.sidebar .active svg path {
    fill: white !important;
}
.sidebar svg path {
    fill: #404d61 !important;
}
.sidebar .active {
    background-color: #0189c7 !important;
}

.mainWidth {
    width: calc(100% - 96px);
}

@media (max-width: 764px) {
    .mainWidth {
        width: calc(100% - 72px);
    }
}
::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

::-webkit-scrollbar-thumb {
    background: #cfcfcf !important;
    border-radius: 10px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.rtl {
    direction: rtl !important;
}
.ltr {
    direction: ltr !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("../public/assets/svg/date.svg") #adacbd no-repeat center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    border-width: thin;
}
.tabsNoti .active {
    font-size: 16px;
    font-weight: bold;
    color: #404d61;
}

.active {
    font-weight: bold;
    text-decoration: underline;
    color: red;
}
