@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fontsource/baloo-chettan-2";
@import "@fontsource/public-sans";

.spin-button-none::-webkit-inner-spin-button,
.spin-button-none::-webkit-outer-spin-button {
    appearance: none;
}

.calc {
    width: calc(100% - 10px) !important;
    height: calc(100% - 10px) !important;
}

* {
    font-family: "Almarai", sans-serif;
}
.recharts-pie-label-line {
    stroke: white !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover{
    background-color:transparent !important
}
/* .recharts-bar{
    visibility: hidden !important;
} */
/* 
.MuiButtonBase-root{
    padding: 0px !important;
} */
.css-78trlr-MuiButtonBase-root-MuiIconButton-root{
    padding: 0px !important;
}